import type { HomeTileViewModel } from '@modules/home/type';
import type { ProjectHomeTileProps } from '@modules/home/util/project-home-tile-view-model.server';
import CategoryNav from '@modules/home/component/common/CategoryNav';
import { useAppData } from '@modules/root';
import type { NavigationItem } from '@archipro-website/top-navigation';
import { HotButtons, useAppDisplayModeContext } from '@archipro-design/aria';
import SectionTitleSlider from '../common/SectionTitleSlider';
import HomeSliderTiles from '../common/HomeSliderTiles';
import HomeProjectTile from './HomeProjectTile';
import { getTrackerData } from '../../util/home-tile-tracker-helpers';
import { useTracker } from '@archipro-website/tracker';
import { useNavigate } from '@remix-run/react';
import { useState } from 'react';
import { useToggleHomePin } from '../../hook/use-toggle-home-pin';
import { getHotButtons } from '~/modules/directory/util/get-hot-buttons';
import { canHomePin } from '../../util/home-pin-helpers';
import MessageModal from '../common/MessageModal';

interface ProjectsModuleProps {
    projectTiles: HomeTileViewModel<ProjectHomeTileProps>[];
}

const ProjectsModule = ({ projectTiles }: ProjectsModuleProps) => {
    const { megamenu } = useAppData();
    const tracker = useTracker();
    const appDisplayMode = useAppDisplayModeContext();
    const { desktop } = appDisplayMode;
    const navigate = useNavigate();

    const categoryNavItems: NavigationItem[] = [
        ...(megamenu?.project.subNavItems
            ?.filter((item) => item.name !== 'New')
            .sort((a, b) => {
                if (a.name === 'Residential') {
                    return -1; // a should come before b
                } else {
                    return a.name.localeCompare(b.name); // sort alphabetically
                }
            }) ?? []),
        {
            id: '100',
            name: 'View all',
            link: '/projects',
        },
    ];

    const navigateClick = (url: string) => {
        navigate(url);
    };

    const { rootGrants } = useAppData();
    const canEditAdmin = rootGrants.editProfile;

    const [pinErrorMessage, setPinErrorMessage] = useState<string | null>(null);
    const { toggleHomePin } = useToggleHomePin(canEditAdmin, (error) => {
        if (error.Message) {
            setPinErrorMessage(error.Message);
        }
    });

    const tiles = projectTiles.map(
        ({ link, id, tile, professionalID }, index) => {
            const trackerData = getTrackerData({
                professionalID: professionalID || 0,
                itemID: id,
                sectionType: 'projects',
                position: index,
                columnPerRow: projectTiles.length, // slider, all elements in one row
            });
            const hotButtons = getHotButtons({
                item: tile,
                itemType: 'project',
                appDisplayMode,
                canEditAdmin,
                canEdit: canEditAdmin,
                pinned: !!tile.homePinned,
                onPinClick: canHomePin(rootGrants)
                    ? () => {
                          toggleHomePin(id, !tile.homePinned);
                      }
                    : undefined,
            });
            return (
                <HomeProjectTile
                    key={link}
                    tracker={tracker}
                    tile={tile}
                    id={id}
                    isMobile={!desktop}
                    link={link}
                    onProfessionalClick={navigateClick}
                    trackerData={trackerData}
                    topActions={
                        hotButtons ? (
                            <HotButtons items={hotButtons} />
                        ) : undefined
                    }
                />
            );
        }
    );

    return (
        <>
            <SectionTitleSlider
                title={`Define your vision by exploring these beautifully designed architectural projects`}
                buttonLink={'/projects'}
                buttonTitle={'Browse projects'}
                categorySlot={
                    desktop && (
                        <CategoryNav
                            items={categoryNavItems}
                            {...(desktop && {
                                groupCTA: {
                                    title: `Browse projects`,
                                    link: `/projects`,
                                },
                            })}
                        />
                    )
                }
            >
                <HomeSliderTiles tiles={tiles} />
            </SectionTitleSlider>
            {pinErrorMessage && (
                <MessageModal
                    title={'Warning'}
                    content={pinErrorMessage}
                    open={true}
                    onConfirm={() => setPinErrorMessage(null)}
                />
            )}
        </>
    );
};

export default ProjectsModule;
