import { useLoaderData } from '@remix-run/react';
import type { loader as homeLoader } from '~/routes/_app._index';
import { useTracker } from '@archipro-website/tracker';
import { useCurrentLocation } from '@ui-rocco/hooks/location/use-current-location';
import { useRootData } from '@modules/root';
import { usePlaceFinder } from '@ui-rocco/hooks/location/use-place-finder';
import type { SupportedCountry } from '../../../../generated/graphql';
import { ClientOnly } from 'remix-utils';
import { HomePageIsland as RoccoHomePageIsland } from '@rocco/ui/floating-island/components/homepage-island/HomePageIsland';
import React from 'react';

export const HomePageIsland = () => {
    const { islandOptions } = useLoaderData<typeof homeLoader>();

    const tracker = useTracker();

    const {
        currentLocation,
        getCurrentLocation,
        loading: currentLocationLoading,
    } = useCurrentLocation();

    const { localisation } = useRootData();

    const {
        places,
        handlePlaceFind,
        resetPlaces,
        loading: placeFinderLoading,
    } = usePlaceFinder({
        country: localisation.country as SupportedCountry,
    });

    return (
        <ClientOnly fallback={<></>}>
            {() => (
                <RoccoHomePageIsland
                    options={islandOptions}
                    locationService={{
                        currentLocation: {
                            isLoading: currentLocationLoading,
                            location: currentLocation,
                            onCurrentLocationFetch: getCurrentLocation,
                        },

                        keywordLocations: {
                            isLoading: placeFinderLoading,
                            locations: places,
                            onKeywordResetPlaces: resetPlaces,
                            onKeywordLocationsFetch: handlePlaceFind,
                        },
                    }}
                    onSubmit={({ type, category, projectType, location }) => {
                        tracker.log('HomeIslandSubmit', {
                            url: new URL(window.location.href),
                            data: {
                                ExtraData: {
                                    type,
                                    category,
                                    projectType,
                                    ...(location?.latitude &&
                                        location?.longitude && {
                                            location: {
                                                latitude: location.latitude,
                                                longitude: location.longitude,
                                            },
                                        }),
                                },
                            },
                        });
                    }}
                />
            )}
        </ClientOnly>
    );
};
