import { useState } from 'react';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@rocco/components/popover';
import { ReactComponent as ArrowChevronDownIcon } from '@rocco/icons/svg/arrow/arrow-chevron-down.svg';

interface FilterSelectProps {
    label: string;
    options: Array<{ title: string; value: string }>;
    value: string;
    defaultValue?: string;
    onSelect: (value: string) => void;
    columnWidth?: string;
    onOpenChange?: (open: boolean) => void;
}

export const FilterSelect = ({
    label,
    options,
    value,
    onSelect,
    columnWidth,
    onOpenChange,
}: FilterSelectProps) => {
    const [open, setOpen] = useState(false);

    const selectedTitle =
        options.find(opt => opt.value === value)?.title || options[0]?.title;

    const handleSelect = (option: { title: string; value: string }) => {
        onSelect(option.value);
        setOpen(false);
        onOpenChange?.(false);
    };

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
        onOpenChange?.(newOpen);
    };

    return (
        <Popover open={open} onOpenChange={handleOpenChange}>
            <PopoverContent
                align="start"
                paddings="xsmall"
                sideOffset={28}
                variant="glassy"
            >
                <div className="flex flex-col gap-1">
                    {options.map(option => (
                        <button
                            key={option.value}
                            className="flex items-center space-x-3 rounded-2 px-4 py-[0.35rem] hover:bg-gray-900/10 text-left outline-none min-w-[18.5rem]"
                            onClick={() => handleSelect(option)}
                        >
                            <span className="text-base tracking-[0.02rem]">
                                {option.title}
                            </span>
                        </button>
                    ))}
                </div>
            </PopoverContent>
            <PopoverTrigger asChild>
                <div
                    className="flex flex-col gap-1 cursor-pointer md:-mr-12"
                    style={columnWidth ? { width: columnWidth } : {}}
                >
                    <div className="text-sm tracking-[0.0175rem] text-gray-900 ">
                        {label}
                    </div>
                    <div className="flex items-center w-full ">
                        <span className="text-base font-medium capitalize text-gray-900 truncate tracking-[0.02rem]">
                            {selectedTitle}
                        </span>
                        <ArrowChevronDownIcon className="ml-[0.6rem] size-[1.1rem] shrink-0 transition-transform group-data-[state=open]:[transform:rotateX(180deg)]" />
                    </div>
                </div>
            </PopoverTrigger>
        </Popover>
    );
};
