import { atom, useSetAtom } from 'jotai';
import { useTracker } from '@archipro-website/tracker';
import { useSharedTiles } from '@rocco/ui/tile-shared/hooks/use-shared-tiles';
import type { RefObject } from 'react';
import { useCallback, useEffect } from 'react';
import type { OnChangeSlideEvent } from '@rocco/components/carousel';
import { getSourceSection } from '~/utils/get-source-section';

export type HomePageTrackingSection =
    | 'HomePageHero'
    | 'HomePageMedia'
    | 'HomePageProducts'
    | 'HomePageProfessionals'
    | 'HomePageProjects'
    | 'HomePageTestimonials'
    | 'HomePageIntro';

/**
 * Context interface for managing home page tracking actions and interactions.
 * @interface HomePageTrackingContext
 */
interface HomePageTrackingContext {
    onStartProjectClick?: (section: HomePageTrackingSection) => void;
    onBecomeProClick?: (section: HomePageTrackingSection) => void;
    onIntroCTAClick?: () => void;
    onMediaCTAClick?: () => void;
    onProjectsCTAClick?: () => void;
    onProductsCTAClick?: () => void;
    onProfessionalsCTAClick?: () => void;
    onCategorySliderSlideChange?: (
        section: HomePageTrackingSection,
        data: OnChangeSlideEvent
    ) => void;
    onCategoryTabClick?: (section: HomePageTrackingSection) => void;
}

/**
 * Atoms for managing home page tracking context.
 * `localContextAtom` stores the core context data privately.
 * `remixHomePageTrackingContext` provides read-only access to the context data.
 */
export const remixHomePageTrackingContext = atom((get) =>
    get(localContextAtom)
);
const localContextAtom = atom<HomePageTrackingContext>({});

/**
 * Hook for tracking events on the home page.
 * Adds tracking to all tiles on the home page using the shared tiles hook.
 * @returns {Object} An object containing the tracking configuration.
 */
export const useHomePageTracking = () => {
    const tracker = useTracker();
    const setContext = useSetAtom(localContextAtom);

    /**
     * Tracks when a user clicks the "Start Project" button on the homepage.
     * Logs the event with the current URL and section information.
     * @param section - The section of the homepage where the click occurred
     */
    const onStartProjectClick = useCallback(
        (section: HomePageTrackingSection) => {
            tracker.log('StartProjectClick', {
                url: new URL(window.location.href),
                data: {
                    ExtraData: section,
                },
            });
        },
        [tracker]
    );

    /**
     * Tracks when a user clicks the "Become a Professional" button on the homepage.
     * Logs the event with the current URL and section information.
     * @param section - The section of the homepage where the click occurred
     */
    const onBecomeProClick = useCallback(
        (section: HomePageTrackingSection) => {
            tracker.log('BecomeProClick', {
                url: new URL(window.location.href),
                data: {
                    ExtraData: section,
                },
            });
        },
        [tracker]
    );

    /**
     * Tracks when a user clicks the CTA button in the homepage intro section.
     * Logs the event with the current URL and 'HomePageIntro' as the section.
     */
    const onIntroCTAClick = useCallback(() => {
        tracker.log('StartProjectClick', {
            url: new URL(window.location.href),
            data: {
                ExtraData: 'HomePageIntro',
            },
        });
    }, [tracker]);

    /**
     * Tracks when a user clicks the CTA button in the homepage media section.
     * Logs the event with the current URL and 'HomePageMedia' as the section.
     */
    const onMediaCTAClick = useCallback(() => {
        tracker.log('HPSignUpBetaClick', {
            url: new URL(window.location.href),
            data: {
                ExtraData: 'HomePageMedia',
            },
        });
    }, [tracker]);

    /**
     * Tracks when a user clicks a category link on the homepage.
     * Logs the event with the current URL and category ID.
     * @param categoryID - The ID of the category that was clicked
     */
    const onCategoryClick = useCallback(
        (categoryID: number) => {
            tracker.log('HPCategoryClick', {
                url: new URL(window.location.href),
                data: {
                    ExtraData: categoryID,
                },
            });
        },
        [tracker]
    );

    const onCategoryTabClick = useCallback(
        (section: HomePageTrackingSection) => {
            tracker.log('HPCategoryTabClick', {
                url: new URL(window.location.href),
                data: {
                    ExtraData: section,
                },
            });
        },
        [tracker]
    );

    const onProjectsCTAClick = useCallback(() => {
        tracker.log('HPCategoryViewAllClick', {
            url: new URL(window.location.href),
            data: {
                ExtraData: 'HomePageProjects',
            },
        });
    }, [tracker]);

    const onProductsCTAClick = useCallback(() => {
        tracker.log('HPCategoryViewAllClick', {
            url: new URL(window.location.href),
            data: {
                ExtraData: 'HomePageProducts',
            },
        });
    }, [tracker]);

    const onProfessionalsCTAClick = useCallback(() => {
        tracker.log('HPCategoryViewAllClick', {
            url: new URL(window.location.href),
            data: {
                ExtraData: 'HomePageProfessionals',
            },
        });
    }, [tracker]);

    /**
     * Tracks when a user clicks on a tile (product, professional, project etc) on the homepage.
     * Logs the event with the current URL and item details.
     * @param targetRef - Reference to the clicked tile element
     * @param itemID - Optional ID of the library item (product, project etc)
     * @param professionalID - Optional ID of the associated professional
     */
    const onTileClick = useCallback(
        (
            targetRef: RefObject<HTMLElement>,
            itemID?: number,
            professionalID?: number
        ) => {
            tracker.log('HPTileClick', {
                url: new URL(window.location.href),
                data: {
                    LibraryItemID: itemID,
                    ProfessionalID: professionalID,
                },
            });
        },
        [tracker]
    );

    const onTileMediaSliderSlideChange = useCallback(
        (direction: 'CarouselPrev' | 'CarouselNext', itemID?: number) => {
            const sectionSource = getSourceSection();
            tracker.log(direction, {
                url: new URL(window.location.href),
                data: {
                    ExtraData: {
                        source: sectionSource,
                        LibraryItemID: itemID,
                    },
                },
            });
        },
        [tracker]
    );

    /**
     * Tracks when a user slides a category slider on the homepage.
     * Logs the event with the current URL and category ID.
     * @param categoryID - The ID of the category that was clicked
     */
    const onCategorySliderSlideChange = useCallback(
        (section: HomePageTrackingSection, data: OnChangeSlideEvent) => {
            tracker.log('HPCategorySliderSlideChange', {
                url: new URL(window.location.href),
                data: {
                    ExtraData: JSON.stringify({ data, section }),
                },
            });
        },
        [tracker]
    );

    // Add tracking to all tiles on the home page using the shared tiles hook
    useSharedTiles({
        trackingConfig: {
            onClick: onTileClick,
            onCategoryClick,
            onTileMediaSliderSlideChange,
        },
    });

    // set the context for the home page tracking (remix related)
    useEffect(() => {
        setContext({
            onStartProjectClick,
            onBecomeProClick,
            onIntroCTAClick,
            onMediaCTAClick,
            onCategorySliderSlideChange,
            onCategoryTabClick,
            onProjectsCTAClick,
            onProductsCTAClick,
            onProfessionalsCTAClick,
        });
    }, [
        setContext,
        onStartProjectClick,
        onBecomeProClick,
        onIntroCTAClick,
        onMediaCTAClick,
        onCategorySliderSlideChange,
        onCategoryTabClick,
        onProjectsCTAClick,
        onProductsCTAClick,
        onProfessionalsCTAClick,
    ]);
};
