import React from 'react';
import { Link, useLoaderData } from '@remix-run/react';
import type { loader as homeLoader } from '~/routes/_app._index';
import { ReactComponent as ArrowRight } from '@rocco/icons/svg/arrow/arrow-right.svg';
import { VideoBackground } from '@rocco/ui/banner/components/VideoBackground';
import { Image } from '@rocco/components/image';
import { TextRotate } from '@rocco/components/text-rotate';
import { Button as RoccoButton } from '@rocco/components/button';
import { useCTAHandlers } from '~/ui-rocco/hooks/use-home-cta-actions';
export const HomePageHero = () => {
    const { heroContent, appDisplayMode } = useLoaderData<typeof homeLoader>();

    const { handleCTAClick, handleLoginClick } = useCTAHandlers('HomePageHero');
    const isMobile = appDisplayMode === 'mobile';
    const { content, video } = heroContent;
    // Create a memoized background element to prevent re-renders
    const backgroundElement = React.useMemo(() => {
        if (video?.videoMp4 || video?.videoWebM) {
            return (
                <VideoBackground
                    {...video}
                    overlay={false}
                    backgroundTheme="transparent"
                />
            );
        }

        if (video?.videoCover) {
            return (
                <Image
                    src={video.videoCover}
                    overrideSrc={video.videoCover}
                    alt="Hero Image"
                    fill={true}
                    fit="cover"
                    priority={true} // Add priority to preload the image
                    loading="eager" // Force eager loading
                />
            );
        }

        return null;
    }, [video]);

    const isLoginButton =
        ['/login', '/login/'].includes(
            content?.CTAButton?.link?.toLowerCase() ?? ''
        ) || !content?.CTAButton?.link; // If no link is provided, default to login button

    return (
        <>
            <section className="relative flex h-[57.3125rem] md:h-[69rem] justify-start bg-brick-500">
                <div className="z-1 qhd:w-full qhd:mx-auto qhd:max-w-[1920px]">
                    <div className="flex w-full md:w-[51.125rem] flex-col px-4 pt-[15.125rem] md:pl-12 md:pt-32 md:mt-[10rem]">
                        <h1
                            aria-label="Your beautiful home begins with ArchiPro"
                            className="max-w-full pb-12 md:pb-[3.2rem] leading-[116%] text-12 md:text-20 md:leading-[110%] tracking-[-0.15rem] text-white m-0"
                        >
                            {isMobile ? (
                                <TextRotate
                                    texts={content?.rotateTexts || []}
                                    prefixText={`${content?.prefix} `}
                                    subfixText={content?.suffix}
                                    pauseDuration={0.5}
                                    rotationDuration={4}
                                />
                            ) : (
                                <>
                                    <TextRotate
                                        texts={content?.rotateTexts || []}
                                        prefixText={`${content?.prefix} `}
                                        pauseDuration={0.5}
                                        rotationDuration={4}
                                    />
                                    <span>{`${content?.suffix}`}</span>
                                </>
                            )}
                        </h1>
                        {!isMobile && (
                            <p className="pr-24 md:pr-0 md:w-[49.0625rem] pb-12 md:pb-[3.2rem] text-4 md:text-5 leading-[130%] tracking-[0.025rem] text-white">
                                {content?.description}
                            </p>
                        )}
                        <div className="md:flex gap-9 items-center">
                            <RoccoButton
                                color="glassy"
                                size={'larger'}
                                inverted={true}
                                asChild
                                onClick={() => {
                                    handleCTAClick('startProject');
                                    if (isLoginButton) {
                                        handleLoginClick();
                                    }
                                }}
                            >
                                {isLoginButton ? (
                                    <span>
                                        {content?.CTAButton?.text ||
                                            'Start Your Project'}
                                        <ArrowRight className="ml-2.5 w-5" />
                                    </span>
                                ) : (
                                    <Link
                                        to={
                                            content?.CTAButton?.link ?? '/login'
                                        }
                                        className="md:pl-[2.125rem] md:tracking-[0.035rem]"
                                    >
                                        {content?.CTAButton?.text ||
                                            'Start Your Project'}
                                        <ArrowRight className="ml-2.5 w-5" />
                                    </Link>
                                )}
                            </RoccoButton>

                            <RoccoButton
                                color="transparent"
                                size={'compact'}
                                shape={'square'}
                                inverted={true}
                                asChild
                            >
                                <Link
                                    to={'/become-a-pro'}
                                    style={{
                                        textDecoration: 'underline',
                                        textUnderlineOffset: '0.3rem',
                                        letterSpacing: '0.035rem',
                                    }}
                                    onClick={() => handleCTAClick('becomePro')}
                                >
                                    {'Become a Pro'}
                                </Link>
                            </RoccoButton>
                        </div>
                    </div>
                </div>
                {backgroundElement}
            </section>
            {isMobile && (
                <div className="px-4 pt-13.5 pb-8">
                    <p className="text-base">{content?.description}</p>
                </div>
            )}
        </>
    );
};
