import { useState } from 'react';
import type { MetaDescriptor } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import invariant from 'tiny-invariant';

import type { StyleRule } from '@archipro-design/aria';
import { useStyles, Container } from '@archipro-design/aria';
import { type DataFunctionArgs } from '@modules/root';
import { getFeaturedProfessionals } from '~/modules/home/api/get-featured-professionals.server';
import { getTopProducts } from '~/modules/home/api/get-top-products.server';
import { useUser } from '@modules/user';
import { getLogoWallData } from '@modules/home/api/get-logo-walls.server';
import { getAdPlacementBlocks } from '@modules/home/api/get-ad-placements.server';
import { processBannerTilesServer } from '@modules/home/util/process-banner-tiles.server';
import { getPageLocalisation } from '~/modules/seo/api/get-page-localisation.server';
import { getFeaturedProjects } from '@modules/home/api/get-featured-projects.server';
import { processMediaBlocks } from '@modules/home/util/process-media-block-tiles';
import { adsRequest } from '@modules/adverts/api/ads-request.server';
import { shuffleArray } from '~/utils/arrayHelper';
import { getPreloadImageLink } from '~/modules/images';
import { createOrganisationSchema } from '~/modules/seo/schema/organisation-schema';
import { createWebsiteSchema } from '~/modules/seo/schema/website-schema';
import { createLDSchema } from '~/modules/seo/util/create-ld-schema';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import { getMetaBaseUrl } from '~/modules/seo/util/create-meta-base-url';
import HomeModule from '@modules/home/component/home-module/HomeModule';
import { getLastPublishedArticles } from '@modules/home/api/get-last-published-articles.server';
import {
    createMetaTitle,
    createMetaDescription,
    getCanonicalHreflang,
    createOGTags,
    mergeMeta,
} from '@modules/seo';
import { getHomePins } from '~/modules/home/api/get-home-pins.server';
import {
    mergeFeaturedArticles,
    mergeFeaturedItems,
    mergeFeaturedProducts,
} from '~/modules/home/util/home-pin-helpers';
import { getRouteData } from '~/modules/root/util/get-route-data';
import { getHomePotm } from '@modules/home/api/get-home-potm.server';
import {
    getNewHomePageloaderData,
    homepageLoaderSkeleton,
} from '@modules-rocco/home/api/get-new-home-loader.server';
import { HomePageHero } from '@ui-rocco/pages/home-page/HomePageHero';
import { HomePageIntro } from '@ui-rocco/pages/home-page/HomePageIntro';
import { HomePageMedia } from '@ui-rocco/pages/home-page/HomePageMedia';
import { HomePageProjects } from '@ui-rocco/pages/home-page/HomePageProjects';
import { HomePageProducts } from '@ui-rocco/pages/home-page/HomePageProducts';
import { HomePageProfessionals } from '@ui-rocco/pages/home-page/HomePageProfessionals';
import { HomePageTestimonials } from '@ui-rocco/pages/home-page/HomePageTestimonials';
import { PageBottomSection } from '@ui-rocco/pages/shared/PageBottomSection';
import { HomePageIsland } from '@ui-rocco/pages/home-page/HomePageIsland';
import { HomePageFAQ } from '@ui-rocco/pages/home-page/HomePageFAQ';
import { useHomePageTracking } from '~/ui-rocco/hooks/use-home-page-tracking';

export const meta = mergeMeta<typeof loader>(
    () => {
        return [];
    },
    (props) => {
        const { matches, data } = props;
        const title = createMetaTitle(
            props,
            [data?.localisation?.Title],
            false
        );
        const description = createMetaDescription([
            data?.localisation?.Description,
        ]);

        const { rootData, appData } = getRouteData(matches);
        const imageConfigProps = appData?.imageConfigProps;

        const baseUrl = getMetaBaseUrl(props);
        invariant(
            rootData?.localisation.country,
            'index.ts meta(): country is required'
        );

        // ToDo: Video schema
        const organisationSchema = createOrganisationSchema(props);
        const websiteSchema = createWebsiteSchema(
            baseUrl,
            rootData.localisation.country
        );

        const ldSchema = createLDSchema([organisationSchema, websiteSchema]);

        const imageSizes = getImagesSizes({
            desktop: 749,
            mobile: 414,
        });
        const bannerImage = data?.bannerTiles[0]?.image.Desktop;
        const bannerImageMobile = data?.bannerTiles[0]?.image.Mobile;
        const mobileImagesLinks = bannerImageMobile
            ? getPreloadImageLink(
                  imageConfigProps,
                  undefined,
                  bannerImageMobile,
                  imageSizes
              )
            : [];
        const desktopImagesLinks = bannerImage
            ? getPreloadImageLink(
                  imageConfigProps,
                  bannerImage,
                  undefined,
                  imageSizes
              )
            : [];
        const preloadImageLinks: MetaDescriptor[] = [];
        if (data?.isLoggedIn) {
            preloadImageLinks.push(
                ...(data?.deviceType === 'mobile'
                    ? mobileImagesLinks
                    : desktopImagesLinks)
            );
        }

        return [
            { title },
            { name: 'description', content: description },
            ...createOGTags({
                meta: props,
                title,
                description,
            }),
            ...getCanonicalHreflang(props),
            ...ldSchema,
            ...preloadImageLinks,
        ];
    }
);

export const loader = async (args: DataFunctionArgs) => {
    const { context, request } = args;
    const requestUrl = request.url;

    const [
        user,
        projectsOfTheMonth,
        professionalTiles,
        productTiles,
        articleTiles,
        featuredProjectTiles,
        homeAds,
        localisation,
        logoWall,
        adPlacementBlocks,
        homePins,
    ] = await Promise.all([
        context.getUser(),
        getHomePotm({ ...args.context }),
        getFeaturedProfessionals({ ...args.context }),
        getTopProducts({ ...args.context }),
        getLastPublishedArticles({ ...args.context }),
        getFeaturedProjects({ ...args.context }),
        adsRequest({ type: 'HomePlacementGroup', requestUrl, ...args.context }),
        getPageLocalisation(args),
        getLogoWallData({ ...args.context }),
        getAdPlacementBlocks({ ...args.context }),
        getHomePins({ ...args.context }),
    ]);

    const shuffledProfessionalTiles = professionalTiles
        ? shuffleArray(professionalTiles).slice(0, 6)
        : [];

    const shuffledProductTiles = productTiles
        ? shuffleArray(productTiles).slice(0, 30)
        : [];

    const shuffledProjectsTiles = featuredProjectTiles
        ? shuffleArray(featuredProjectTiles).slice(0, 6)
        : [];

    const bannerTiles = shuffleArray(
        processBannerTilesServer(
            args,
            homeAds && homeAds.HomeBannerPlacement
                ? homeAds.HomeBannerPlacement
                : []
        )
    );

    const mediaBlocks = shuffleArray(
        processMediaBlocks(
            context,
            homeAds && homeAds.HomeHalfWidthPlacement
                ? homeAds.HomeHalfWidthPlacement
                : []
        ).slice(0, 2)
    );

    const homeFeatureAds =
        homeAds && homeAds.HomeFeaturePlacement
            ? shuffleArray(homeAds.HomeFeaturePlacement)
            : [];

    // Extract first project of the month and remove it from the array
    const firstProjectOfTheMonth = projectsOfTheMonth.shift();

    const isLoggedIn = user.__typename === 'Me';

    // rocco specific loader
    let roccoHomePageData: Awaited<
        ReturnType<typeof getNewHomePageloaderData>
    > = !isLoggedIn
        ? await getNewHomePageloaderData(context, request)
        : homepageLoaderSkeleton;

    return {
        deviceType: context.device.type,
        bannerTiles,
        shuffledProfessionalTiles,
        shuffledProductTiles,
        firstProjectOfTheMonth,
        projectsOfTheMonth,
        shuffledProjectsTiles,
        articleTiles: articleTiles,
        homeFeatureAds,
        localisation,
        mediaBlocks: mediaBlocks,
        logoWall,
        adPlacements: adPlacementBlocks,
        productPins: homePins?.products ?? [],
        projectPins: homePins?.projects ?? [],
        professionalPins: homePins?.professionals ?? [],
        articlePins: homePins?.articles ?? [],

        // Rocco base
        ...roccoHomePageData,
        isLoggedIn,
        pageTheme: isLoggedIn ? 'default' : 'transparent',
    };
};

const LoggedInHomePage = () => {
    const { css } = useStyles();
    const user = useUser();
    const {
        bannerTiles,
        shuffledProfessionalTiles,
        shuffledProductTiles,
        firstProjectOfTheMonth,
        projectsOfTheMonth,
        articleTiles,
        shuffledProjectsTiles,
        mediaBlocks,
        logoWall,
        adPlacements,
        homeFeatureAds,
        productPins,
        projectPins,
        professionalPins,
        articlePins,
    } = useLoaderData<typeof loader>();

    // Keep the first value returned by loader to avoid changing when loader revalidate
    const [bannerTitlesMemoized] = useState(bannerTiles);
    const [professionalTitlesMemoized] = useState(shuffledProfessionalTiles);
    const [productTitleMemoized] = useState(shuffledProductTiles);
    const [projectTitleMemoized] = useState(shuffledProjectsTiles);
    const [mediaBlocksMemoized] = useState(mediaBlocks);
    const [homeFeatureAdsMemoized] = useState(homeFeatureAds);

    const featuredProducts = mergeFeaturedProducts(
        productPins,
        productTitleMemoized,
        24,
        homeFeatureAdsMemoized.length
    );
    const featuredProjects = mergeFeaturedItems(
        projectPins,
        projectTitleMemoized,
        6
    );
    const featuredProfessionals = mergeFeaturedItems(
        professionalPins,
        professionalTitlesMemoized,
        6
    );
    const featuredArticles = mergeFeaturedArticles(
        articlePins,
        articleTiles,
        2
    );

    return (
        <Container className={css(HomeContainer)}>
            <HomeModule
                bannerTiles={bannerTitlesMemoized}
                professionalTiles={featuredProfessionals}
                productTiles={featuredProducts}
                firstProjectOfTheMonth={firstProjectOfTheMonth}
                projectsOfTheMonth={projectsOfTheMonth}
                articleTiles={featuredArticles}
                homeFeatureAds={homeFeatureAdsMemoized}
                user={user}
                mediaBlocks={mediaBlocksMemoized}
                logoWall={logoWall}
                adPlacements={adPlacements}
                featuredProjectTiles={featuredProjects}
            />
        </Container>
    );
};

const HomePage = () => {
    const { appDisplayMode } = useLoaderData<typeof loader>();
    const isMobile = appDisplayMode === 'mobile';

    // initialize home page tracking
    useHomePageTracking();

    return (
        <>
            <HomePageHero />
            <HomePageIntro />
            <HomePageMedia />
            <HomePageProjects />
            <HomePageProducts />
            <HomePageProfessionals />
            <HomePageTestimonials />
            <HomePageFAQ />
            <div className="my-10 flex flex-col gap-10 md:my-[12.8125rem] md:gap-[13.75rem]">
                <PageBottomSection />
            </div>

            {!isMobile ? <HomePageIsland /> : null}
        </>
    );
};

const HomePageSwitch = () => {
    const { isLoggedIn } = useLoaderData<typeof loader>();
    return !isLoggedIn ? <HomePage /> : <LoggedInHomePage />;
};

export default HomePageSwitch;

const HomeContainer: StyleRule = ({ theme }) => ({
    width: '100%',
    color: theme.siteVariables.bodyColor,
    background: theme.siteVariables.bodyBackground,
});
