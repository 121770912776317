/**
 * Custom hook for handling homepage call-to-action interactions
 * and their associated tracking events.
 */
import { useSetLoginModal } from '~/modules-rocco/user/hook/use-open-login-modal';
import { useAtomValue } from 'jotai';
import type { HomePageTrackingSection } from '~/ui-rocco/hooks/use-home-page-tracking';
import { remixHomePageTrackingContext } from '~/ui-rocco/hooks/use-home-page-tracking';

/**
 * Provides handlers for various call-to-action buttons across different homepage sections.
 * @param section - The current homepage section where the CTA appears
 * @returns Object containing handler functions for CTA interactions
 */
export const useCTAHandlers = (section: HomePageTrackingSection) => {
    const { setLoginModal } = useSetLoginModal();
    const trackingContext = useAtomValue(remixHomePageTrackingContext);

    /**
     * Handles clicks on primary CTA buttons based on the current section
     * @param type - Optional type of CTA button ('startProject' or 'becomePro')
     */
    const handleCTAClick = (type?: 'startProject' | 'becomePro') => {
        // Handle hero section CTAs with specific types
        if (section === 'HomePageHero') {
            if (type === 'startProject') {
                trackingContext.onStartProjectClick?.(section);
            } else if (type === 'becomePro') {
                trackingContext.onBecomeProClick?.(section);
            }
            return;
        }

        // Map sections to their corresponding tracking functions
        const sectionHandlers: Record<
            HomePageTrackingSection,
            (() => void) | undefined
        > = {
            HomePageHero: undefined, // Already handled above
            HomePageIntro: trackingContext.onIntroCTAClick,
            HomePageMedia: trackingContext.onMediaCTAClick,
            HomePageProjects: trackingContext.onProjectsCTAClick,
            HomePageProducts: trackingContext.onProductsCTAClick,
            HomePageProfessionals: trackingContext.onProfessionalsCTAClick,
            HomePageTestimonials: undefined,
        };

        // Call the appropriate handler if it exists
        sectionHandlers[section]?.();
    };

    /**
     * Handles clicks on category tabs
     * @param section - The homepage section where the category tab appears
     */
    const handleCategoryTabClick = (section: HomePageTrackingSection) => {
        trackingContext.onCategoryTabClick?.(section);
    };

    /**
     * Handles clicks on login-related CTAs
     */
    const handleLoginClick = () => {
        setLoginModal({
            authSource: 'homeModule',
            isOpen: true,
        });
    };

    return {
        handleCTAClick,
        handleCategoryTabClick,
        handleLoginClick,
    };
};
