import React, { useRef } from 'react';
import { Link, useLoaderData } from '@remix-run/react';
import type { loader as homeLoader } from '~/routes/_app._index';
import { ReactComponent as ArrowRight } from '@rocco/icons/svg/arrow/arrow-right.svg';
import {
    formatNumberWithCommas,
    roundToNearest,
} from '@rocco/utils/format-number';
import { FilterTabs } from '@rocco/ui/filter-sort/components/filters-bar/FilterTabs';
import { Grid } from '@rocco/ui/layout/components/Grid';
import { Tile } from '@rocco/ui/tile-shared/components/Tile';
import type { TileViewModel } from '@rocco/ui/tile-shared/view-models/tile';
import type { ProjectTileViewModel } from '@rocco/ui/tile-project/view-models/project';
import { Button as RoccoButton } from '@rocco/components/button';
import { useLogImpression } from '@archipro-website/tracker';
import { useCTAHandlers } from '~/ui-rocco/hooks/use-home-cta-actions';
export const HomePageProjects = () => {
    const { projectSection, appDisplayMode } =
        useLoaderData<typeof homeLoader>();

    const { handleCTAClick, handleCategoryTabClick } =
        useCTAHandlers('HomePageProjects');

    return (
        <section className="py-20 md:py-[10.25rem] qhd:mx-auto qhd:max-w-[1920px]">
            <div className="px-6 md:px-0 flex flex-col items-center justify-center gap-6 md:gap-12 pb-6 md:pb-[6.375rem]">
                <h3 className="text-center md:flex text-3xl md:gap-10.5 md:text-20 leading-[127.78%] md:leading-[100%] tracking-[-0.125rem]">
                    <span>{`${projectSection.prefixTitle} `}</span>
                    <span className="inline-block rounded-full border-2 border-black px-4 md:px-7.5 py-0.5 leading-[100%] mx-2 md:mx-0">{`${formatNumberWithCommas(roundToNearest(projectSection.count))}+`}</span>
                    <span>{`${projectSection.suffixTitle}`}</span>
                </h3>
                <p className="py-1.5 text-center text-gray-700 md:max-w-[46.875rem] text-base md:text-5 md:leading-[130%] md:tracking-[0.025rem]">
                    {projectSection.description}
                </p>
            </div>
            <div className="pt-4.5 md:px-12">
                <div className="flex flex-col gap-6.5 md:gap-17">
                    <div className="pl-6 md:pl-0 md:mt-0 md:block">
                        <div className="scrollbar-hide overflow-x-auto flex w-full items-center">
                            <FilterTabs
                                tabs={projectSection.filterTabs}
                                displayMode={appDisplayMode}
                                onTabClick={() =>
                                    handleCategoryTabClick?.('HomePageProjects')
                                }
                            />
                        </div>
                    </div>
                    <div className="px-6 md:px-0">
                        <Grid
                            colClasses="grid-cols-1 md:grid-cols-2 md:grid-cols-4"
                            rowsGapClass="gap-y-12 md:gap-y-26"
                        >
                            {projectSection.tiles.map((tile) => (
                                <HomePageProjectsTile
                                    key={`featured-project-tile-${tile.model.id}`}
                                    tile={
                                        tile as TileViewModel<ProjectTileViewModel>
                                    }
                                />
                            ))}
                        </Grid>
                    </div>
                </div>
            </div>
            <div className="mt-12 text-center md:mt-30">
                <RoccoButton color="primary" asChild>
                    <Link
                        to={projectSection.CTAButton?.link ?? '/projects'}
                        onClick={() => handleCTAClick()}
                    >
                        {projectSection.CTAButton?.text}
                        <ArrowRight className="ml-1.5 w-4" />
                    </Link>
                </RoccoButton>
            </div>
        </section>
    );
};

const HomePageProjectsTile = ({
    tile,
}: {
    tile: TileViewModel<ProjectTileViewModel>;
}) => {
    const tileRef = useRef<HTMLDivElement>(null);

    useLogImpression({
        data: {
            type: 'LibraryItem_Directory',
            itemID: tile.model.id,
            professionalID: tile.model.professional.id,
        },
        ref: tileRef,
    });

    return (
        <div ref={tileRef}>
            <Tile tile={tile} />
        </div>
    );
};
