import { useState, useEffect } from 'react';
import { Link } from '@remix-run/react';
import { Button } from '@rocco/components/button';
import { ReactComponent as SearchIcon } from '@rocco/icons/svg/search.svg';
import { FloatingIsland } from '../FloatingIsland';
import { FilterSelect } from './FilterSelect';
import { FilterLocation } from './FilterLocation';
import { DEFAULT_TYPE_OPTIONS, DEFAULT_PROJECT_TYPE_OPTIONS } from './types';
import { getCategoryDefaultOptions } from './utils';
import type { HomePageIslandProps } from './types';
import type { LocationPlace } from '@rocco/ui/location/types/location-place';

export const HomePageIsland = (props: HomePageIslandProps) => {
    const { options, locationService, onSubmit } = props;

    const [selectedType, setSelectedType] = useState(
        DEFAULT_TYPE_OPTIONS.defaultValue,
    );

    const [selectedProjectType, setSelectedProjectType] = useState(
        DEFAULT_PROJECT_TYPE_OPTIONS.defaultValue,
    );

    const [selectedCategory, setSelectedCategory] = useState(
        getCategoryDefaultOptions(selectedType, options)[0].value,
    );
    const [selectedLocation, setSelectedLocation] =
        useState<LocationPlace | null>(null);

    const [targetLink, setTargetLink] = useState('');

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleTypeSelect = (value: string) => {
        setSelectedType(value);
        setTargetLink(getCategoryDefaultOptions(value, options)[0].value);
    };

    const handleProjectTypeSelect = (value: string) => {
        setSelectedProjectType(value);
    };

    const handleCategorySelect = (value: string) => {
        setSelectedCategory(value);
    };

    useEffect(() => {
        const params = new URLSearchParams();

        if (selectedLocation) {
            const { suburb, city, region, state, country } = selectedLocation;
            const locationEntries = {
                suburb,
                city,
                region,
                'location-state': state,
                country,
            };

            Object.entries(locationEntries).forEach(([key, value]) => {
                if (value) params.append(key, value);
            });
        }

        setTargetLink(
            `${selectedCategory ? selectedCategory : ''}${
                params.toString() ? `?${params.toString()}` : ''
            }`,
        );
    }, [selectedLocation, selectedCategory]);

    return (
        <>
            <FloatingIsland variant="home" scrollThreshold={0}>
                <div className="flex justify-between">
                    <div
                        className={
                            'grid grid-cols-[7.5rem_7.5rem_7.5rem_1fr] items-center md:gap-17.5'
                        }
                    >
                        <FilterSelect
                            label={DEFAULT_TYPE_OPTIONS.label}
                            options={DEFAULT_TYPE_OPTIONS.options}
                            value={selectedType}
                            onSelect={handleTypeSelect}
                            onOpenChange={setIsDropdownOpen}
                        />
                        <FilterSelect
                            label={'Category'}
                            options={getCategoryDefaultOptions(
                                selectedType,
                                options,
                            )}
                            value={selectedCategory}
                            onSelect={handleCategorySelect}
                            onOpenChange={setIsDropdownOpen}
                        />
                        <FilterSelect
                            key={selectedProjectType}
                            label={DEFAULT_PROJECT_TYPE_OPTIONS.label}
                            options={DEFAULT_PROJECT_TYPE_OPTIONS.options}
                            value={selectedProjectType}
                            onSelect={handleProjectTypeSelect}
                            onOpenChange={setIsDropdownOpen}
                        />
                        {selectedType === 'professional' && locationService && (
                            <FilterLocation
                                locationService={locationService}
                                onLocationChange={setSelectedLocation}
                                onOpenChange={setIsDropdownOpen}
                            />
                        )}
                    </div>
                    <div className="flex items-center gap-x-2">
                        <Button
                            type="submit"
                            color={'dark'}
                            size={'square72'}
                            aria-label="Submit Search"
                            asChild
                        >
                            <Link
                                to={targetLink ?? ''}
                                prefetch="render"
                                onClick={(
                                    e: React.MouseEvent<HTMLAnchorElement>,
                                ) => {
                                    if (targetLink === '') {
                                        e.preventDefault();
                                    }

                                    onSubmit({
                                        type: selectedType,
                                        category: selectedCategory,
                                        ...(selectedType === 'professional' && {
                                            projectType: selectedProjectType,
                                            location: selectedLocation,
                                        }),
                                    });
                                }}
                            >
                                <SearchIcon className="size-6 stroke-white" />
                            </Link>
                        </Button>
                    </div>
                </div>
            </FloatingIsland>
            {isDropdownOpen && (
                <div
                    className={`fixed inset-0 z-dropdown bg-gray-900/30 backdrop-blur-sm ease-out duration-300 animate-fade-in`}
                />
            )}
        </>
    );
};
