import { useState, useEffect } from 'react';
import { Input } from '@rocco/components/input';
import { Search, LoaderCircle } from 'lucide-react';
import { ReactComponent as CompassIcon } from '@rocco/icons/svg/compass.svg';
import { ReactComponent as ArrowChevronDownIcon } from '@rocco/icons/svg/arrow/arrow-chevron-down.svg';
import { LocationPlace } from '@rocco/ui/location/types/location-place';
import type { LocationServiceOptions } from '@rocco/ui/filter-sort/hooks/use-search-filters';
import { formatLocation } from '@rocco/utils/format-location';
import { cn } from '@rocco/utils/cn';
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@rocco/components/popover';

interface FilterLocationProps {
    locationService: LocationServiceOptions;
    onLocationChange: (location: LocationPlace) => void;
    columnWidth?: string;
    onOpenChange?: (open: boolean) => void;
}

export const FilterLocation = ({
    locationService,
    onLocationChange,
    columnWidth,
    onOpenChange,
}: FilterLocationProps) => {
    const [open, setOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(
        locationService.currentLocation.location,
    );
    const [suggestions, setSuggestions] = useState<LocationPlace[]>([]);
    const [isSuggesting, setSuggesting] = useState(false);
    const [keyword, setKeyword] = useState('');

    const handleKeywordChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ): void => {
        if (event.currentTarget) {
            const value = event.currentTarget.value;

            setKeyword(value);

            if (value.trim().length > 2) {
                locationService.keywordLocations.onKeywordLocationsFetch(
                    value,
                    10,
                );
            }

            if (value.trim().length === 0) {
                setSuggestions([]);
            }
        }
    };

    const handleSuggestionSelect = (location: LocationPlace) => {
        setSelectedLocation(location);
        setKeyword(formatLocation(location));
        setSuggestions([]);
        setOpen(false);
    };

    // update suggestions when keyword locations change
    useEffect(() => {
        if (locationService.keywordLocations.locations) {
            setSuggestions(locationService.keywordLocations.locations);
            setSuggesting(true);
        }
    }, [locationService.keywordLocations.locations]);

    // set current location
    useEffect(() => {
        if (locationService.currentLocation.location) {
            setSelectedLocation(locationService.currentLocation.location);
            setOpen(false);
        }
    }, [locationService.currentLocation.location]);

    useEffect(() => {
        if (selectedLocation) {
            onLocationChange(selectedLocation);
        }
    }, [selectedLocation, onLocationChange]);

    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
        onOpenChange?.(newOpen);
    };

    return (
        <Popover open={open} onOpenChange={handleOpenChange}>
            <PopoverContent
                align="start"
                paddings="xsmall"
                sideOffset={28}
                variant="glassy"
            >
                <div className="flex flex-col gap-2 min-w-[21.5rem]">
                    <div className="w-full">
                        <Input
                            color="gray"
                            text="default"
                            placeholder="Enter your project location"
                            value={keyword}
                            showClearButton={true}
                            postfix={
                                locationService.keywordLocations.isLoading ? (
                                    <LoaderCircle className="animate-spin size-4" />
                                ) : (
                                    <Search
                                        className={cn(
                                            'size-4',
                                            keyword.length === 0 &&
                                                'opacity-50',
                                        )}
                                    />
                                )
                            }
                            onChange={handleKeywordChange}
                            onClear={() => {
                                setSelectedLocation(null);
                                setKeyword('');
                                setSuggestions([]);
                            }}
                        />
                    </div>
                    {isSuggesting && suggestions.length > 0 && (
                        <div className="mt-2 flex flex-col gap-1">
                            {suggestions.map((suggestion, index) => (
                                <div
                                    key={index}
                                    className="cursor-pointer rounded-2 px-4 py-[0.35rem] hover:bg-gray-900/10"
                                    onClick={() =>
                                        handleSuggestionSelect(suggestion)
                                    }
                                >
                                    {formatLocation(suggestion)}
                                </div>
                            ))}
                        </div>
                    )}
                    <div
                        className="flex cursor-pointer rounded-2 px-4 py-[0.35rem] hover:bg-gray-900/10 gap-2 cursor-pointer items-center"
                        onClick={
                            locationService.currentLocation
                                .onCurrentLocationFetch
                        }
                    >
                        <CompassIcon className="size-4.5" />
                        <span className="underline underline-offset-2">
                            {locationService.currentLocation.location
                                ? formatLocation(
                                      locationService.currentLocation.location,
                                  )
                                : `Use my current location`}
                        </span>
                        {locationService.currentLocation.isLoading && (
                            <div className="flex-centered size-4 opacity-50">
                                <LoaderCircle className="animate-spin size-4" />
                            </div>
                        )}
                    </div>
                </div>
            </PopoverContent>
            <PopoverTrigger asChild>
                <div
                    className="flex flex-col gap-1 cursor-pointer"
                    style={columnWidth ? { width: columnWidth } : {}}
                >
                    <div className="text-sm tracking-[0.0175rem] text-gray-900">
                        {`Location`}
                    </div>
                    <div className="flex items-center w-full ">
                        <span className="text-base font-medium capitalize text-gray-900 line-clamp-2 tracking-[0.02rem] max-w-40">
                            {selectedLocation
                                ? formatLocation(selectedLocation)
                                : `Select location`}
                        </span>
                        <ArrowChevronDownIcon className="ml-[0.6rem] size-[1.1rem] transition-transform group-data-[state=open]:[transform:rotateX(180deg)]" />
                    </div>
                </div>
            </PopoverTrigger>
        </Popover>
    );
};
