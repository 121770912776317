import type { StyleRule } from '@archipro-design/aria';
import {
    pxToRem,
    pxArrayToRem,
    imageOverlayClassName,
} from '@archipro-design/aria';
import { hex2rgba } from '~/utils';

export const Card: StyleRule = ({ theme }) => ({
    position: 'relative',
    width: pxToRem(378),
    height: pxToRem(496),
    padding: pxArrayToRem([26, 18, 35]),
    borderRadius: '0.625rem',
    overflow: 'hidden',

    [theme.screen.tablet]: {
        padding: 0,
        width: pxToRem(709),
        height: pxToRem(932),
    },
});

export const ImageWrap: StyleRule = ({ theme }) => ({
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,

    [`& img`]: {
        transform: `scale(1.07)`,
        transition: `transform 150ms`,
    },

    [`&:hover img`]: {
        transform: `scale(1.0)`,
    },

    [`& a.${imageOverlayClassName}`]: {
        background: hex2rgba(theme.siteVariables.colors.primitive.black, 0),
        color: theme.siteVariables.colors.primitive.white,

        '& svg': {
            opacity: 0,
            transform: `translate(-100%, -50%)`,
            width: pxToRem(18),
        },

        '&:hover': {
            background: hex2rgba(
                theme.siteVariables.colors.primitive.black,
                0.3
            ),
            '& svg': {
                opacity: 1,
                transform: `translate(-50%, -50%)`,
            },
        },
    },
});

export const Overlay: StyleRule = ({ theme }) => ({
    position: 'relative',
    zIndex: '1',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    pointerEvents: 'none',
    color: theme.siteVariables.colors.primitive.white,
    [theme.screen.tablet]: {
        padding: pxArrayToRem([50, 76, 76]),
    },
});

export const Title: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Heading.Roman55,
    lineHeight: theme.siteVariables.lineHeightDefault,
    color: 'inherit',
});

export const Subtitle: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Heading.Roman55,
    textTransform: 'uppercase',
    lineHeight: theme.siteVariables.lineHeightDefault,
    marginBottom: pxToRem(11),
    color: 'inherit',
    [theme.screen.tablet]: {
        ...theme.siteVariables.textStyles.Text.Roman55,
        marginBottom: pxToRem(27),
    },
});

export const ViewLink: StyleRule = () => ({
    marginTop: 'auto',
    color: 'inherit',
});
