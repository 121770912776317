import type { FilterCategoryLevelViewModel } from '@rocco/ui/filter-sort/view-models/filter-category';

// Define a type for the individual options
interface CategoryOption {
    title: string;
    value: string;
}

// Define a type for the array that ensures at least one element
type CategoryOptionsArray = [CategoryOption, ...CategoryOption[]];

export const getCategoryDefaultOptions = (
    type: string,
    options: {
        professionalOptions: FilterCategoryLevelViewModel[];
        productOptions: FilterCategoryLevelViewModel[];
        projectOptions: FilterCategoryLevelViewModel[];
    },
): CategoryOptionsArray => {
    let defaultOptions: CategoryOption = {
        title: 'All Categories',
        value: '/products',
    };
    let tree: CategoryOption[] = [];

    switch (type) {
        case 'products': {
            defaultOptions = {
                title: 'All Categories',
                value: '/products',
            };
            tree =
                options.productOptions
                    .find(opt => opt.level === 0)
                    ?.categories[0]?.links.map(opt => ({
                        title: opt.title,
                        value: opt.link,
                    })) ?? [];
            break;
        }
        case 'projects': {
            defaultOptions = {
                title: 'All Categories',
                value: '/projects',
            };
            tree =
                options.projectOptions
                    .find(opt => opt.level === 0)
                    ?.categories[0]?.links.map(opt => ({
                        title: opt.title,
                        value: opt.link,
                    })) ?? [];
            break;
        }
        case 'professional':
        default: {
            defaultOptions = {
                title: 'All Categories',
                value: '/professionals',
            };
            tree =
                options.professionalOptions
                    .find(opt => opt.level === 0)
                    ?.categories[0]?.links.map(opt => ({
                        title: opt.title,
                        value: opt.link,
                    })) ?? [];
            break;
        }
    }

    return [defaultOptions, ...tree];
};

export const getCategoryTree = (
    type: string,
    options: {
        professionalOptions: FilterCategoryLevelViewModel[];
        productOptions: FilterCategoryLevelViewModel[];
        projectOptions: FilterCategoryLevelViewModel[];
    },
) => {
    switch (type) {
        case 'professional':
            return options.professionalOptions;
        case 'products':
            return options.productOptions;
        case 'projects':
            return options.projectOptions;
        default:
            return [];
    }
};
