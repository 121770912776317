import React from 'react';
import { useLoaderData } from '@remix-run/react';
import type { loader as homeLoader } from '~/routes/_app._index';
import { ReactComponent as ArrowRight } from '@rocco/icons/svg/arrow/arrow-right.svg';
import { ReactComponent as StarIcon } from '@rocco/icons/svg/star.svg';
import { HomeCarouselSection } from '@rocco/ui/layout/components/HomeCarouselSection';
import { cn } from '@rocco/utils/cn';
import { Image } from '@rocco/components/image';
import { Button as RoccoButton } from '@rocco/components/button';
import { useAtomValue } from 'jotai';
import { remixHomePageTrackingContext } from '~/ui-rocco/hooks/use-home-page-tracking';

export const HomePageTestimonials = () => {
    const { testimonialSection, appDisplayMode } =
        useLoaderData<typeof homeLoader>();
    const [activeIndex, setActiveIndex] = React.useState(0);
    const { onStartProjectClick } = useAtomValue(remixHomePageTrackingContext);

    return (
        <section className="bg-[#F2F2F2] py-20 md:py-48">
            <HomeCarouselSection
                displayMode={appDisplayMode}
                title={testimonialSection?.title}
                description={testimonialSection?.description}
                onSlideChange={setActiveIndex}
            >
                {testimonialSection?.testimonials?.map((item, index) => (
                    <div
                        key={`testimonials-${index}`}
                        className={cn(
                            'flex flex-col rounded-2 backdrop-blur-[0.46875rem] md:h-[42.7rem] aspect-[280/350] md:aspect-[600/690] p-4.5 md:pb-12 md:pl-12 md:pr-[3.125rem] md:pt-[3.125rem] transition-colors duration-300',
                            activeIndex === index
                                ? 'bg-[#AC5B39]'
                                : 'bg-[#717677]'
                        )}
                    >
                        <div className="pb-[2.375rem] md:pb-[3.2rem] text-right">
                            <div className="relative ml-auto flex size-21 md:size-[14.5625rem] items-center justify-center overflow-hidden rounded-full bg-gray-300">
                                {item.image ? (
                                    <Image
                                        src={item.image}
                                        alt={item.author}
                                        fill={true}
                                        loading="lazy"
                                        fit="cover"
                                    />
                                ) : null}
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 md:gap-12">
                            <span className="pb-[0.35rem] text-sm md:text-7 leading-[142.85%] md:leading-[121.43%] md:w-[30.4375rem]">
                                {item.quote}
                            </span>
                            <div className="flex">
                                {[...Array(item.rating)].map((_, index) => (
                                    <StarIcon
                                        key={index}
                                        className="size-4 md:size-6"
                                    />
                                ))}
                            </div>
                            <div className="mt-4 md:mt-auto">
                                <RoccoButton
                                    color={
                                        activeIndex === index
                                            ? 'glassy'
                                            : 'gray'
                                    }
                                    inverted={activeIndex === index}
                                    onClick={() =>
                                        onStartProjectClick?.(
                                            'HomePageTestimonials'
                                        )
                                    }
                                >
                                    {`Start your Project`}
                                    <ArrowRight className="ml-1.5 w-4" />
                                </RoccoButton>
                            </div>
                        </div>
                    </div>
                ))}
            </HomeCarouselSection>
        </section>
    );
};
