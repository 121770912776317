import type { LocationServiceOptions } from '@rocco/ui/filter-sort/hooks/use-search-filters';
import type { FilterCategoryLevelViewModel } from '@rocco/ui/filter-sort/view-models/filter-category';
import type { LocationPlace } from '@rocco/ui/location/types/location-place';
export interface HomePageIslandProps {
    options: {
        productOptions: FilterCategoryLevelViewModel[];
        professionalOptions: FilterCategoryLevelViewModel[];
        projectOptions: FilterCategoryLevelViewModel[];
    };
    locationService?: LocationServiceOptions;
    onSubmit: ({
        type,
        category,
        projectType,
        location,
    }: {
        type: string;
        category?: string;
        projectType?: string;
        location?: LocationPlace | null;
    }) => void;
}

export const DEFAULT_TYPE_OPTIONS = {
    label: `Looking For`,
    options: [
        { id: 1, title: 'Professionals', value: 'professional' },
        {
            id: 2,
            title: 'Products',
            value: 'products',
        },
        { id: 3, title: 'Projects', value: 'projects' },
    ],
    defaultValue: 'professional',
};

export const DEFAULT_PROJECT_TYPE_OPTIONS = {
    label: `Project Type`,
    options: [
        { id: 0, title: 'All Types', value: 'all' },
        { id: 1, title: 'Renovation', value: 'renovation' },
        { id: 2, title: 'New Build', value: 'new-build' },
        { id: 3, title: 'Interior Design', value: 'interior-design' },
        { id: 4, title: 'Exterior Design', value: 'exterior-design' },
    ],
    defaultValue: 'all',
};
