import React from 'react';
import { Link, useLoaderData } from '@remix-run/react';
import type { loader as homeLoader } from '~/routes/_app._index';
import { ReactComponent as ArrowRight } from '@rocco/icons/svg/arrow/arrow-right.svg';
import {
    formatNumberWithCommas,
    roundToNearest,
} from '@rocco/utils/format-number';
import { Button as RoccoButton } from '@rocco/components/button';
import { HomePageFeaturedCategories } from '@ui-rocco/pages/home-page/HomePageFeaturedCategories';
import { useCTAHandlers } from '~/ui-rocco/hooks/use-home-cta-actions';

export const HomePageProducts = () => {
    const { productSection, appDisplayMode } =
        useLoaderData<typeof homeLoader>();
    const { handleCTAClick } = useCTAHandlers('HomePageProducts');

    return (
        <section className="bg-[#EDEDED] py-20 md:py-[10.25rem]">
            <div className="flex flex-col items-center justify-center gap-6 md:gap-12 pb-8 md:pb-24 px-4 md:px-0">
                <h3 className="text-center md:flex text-3xl gap-10.5 md:text-20 leading-[127.78%] md:leading-[100%] tracking-[-0.125rem]">
                    <span>{`${productSection.prefixTitle} `}</span>
                    <span className="inline-block rounded-full border-2 border-black px-4 md:px-7.5 py-0.5 leading-[100%] mx-2 md:mx-0">{`${formatNumberWithCommas(roundToNearest(productSection.count))}+`}</span>
                    <span>{`${productSection.suffixTitle}`}</span>
                </h3>
                <p className="px-6 md:px-0 py-2.5 text-center text-gray-700 md:max-w-[46.875rem] text-base md:text-5 md:leading-[130%] md:tracking-[0.025rem]">
                    {productSection.description}
                </p>
            </div>
            <HomePageFeaturedCategories
                appDisplayMode={appDisplayMode}
                categories={productSection.categories}
                title={productSection.categorySliderTitle ?? ''}
                section="HomePageProducts"
            />
            <div className="mt-12 text-center md:mt-[6.7rem]">
                <div className="flex flex-col gap-4 gap-4 md:flex-row justify-center items-center">
                    <RoccoButton color="primary" asChild>
                        <Link
                            to={productSection.CTAButton?.link ?? '/products'}
                            onClick={() => handleCTAClick()}
                        >
                            {productSection.CTAButton?.text}
                            <ArrowRight className="ml-1.5 w-4" />
                        </Link>
                    </RoccoButton>
                    <RoccoButton color="dark" asChild>
                        <Link to={'/products/suppliers'}>
                            {'Browser Suppliers'}
                            <ArrowRight className="ml-1.5 w-4" />
                        </Link>
                    </RoccoButton>
                </div>
            </div>
        </section>
    );
};
