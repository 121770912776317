import React from 'react';
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselPrevious,
    CarouselNext,
    type OnChangeSlideEvent,
} from '@rocco/components/carousel';
import { ReactComponent as ArrowLeft } from '@rocco/icons/svg/arrow/arrow-left.svg';
import { ReactComponent as ArrowRight } from '@rocco/icons/svg/arrow/arrow-right.svg';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { DisplayMode } from '@rocco/types/enum/display-mode';

const _carouselItemVariant = cva(
    'first:ml-6 md:first:ml-0 mx-2 md:mx-3 last:mr-6 md:last:mr-[30.5625rem] max-w-[17.5rem] md:max-w-[37.125rem] transition-colors duration-300',
    {
        variants: {
            size: {
                default: 'basis-full',
                medium: 'md:max-w-[17rem]',
                small: 'w-44',
            },
        },
        defaultVariants: {
            size: 'default',
        },
    },
);

const _themeVariants = cva('', {
    variants: {
        theme: {
            light: '',
            dark: 'text-white',
        },
    },
});

interface HomeCarouselSectionProps
    extends React.PropsWithChildren,
        VariantProps<typeof _carouselItemVariant>,
        VariantProps<typeof _themeVariants> {
    title?: string;
    showArrows?: boolean;
    description?: string;
    onSlideChange?: (index: number) => void;
    className?: string;
    displayMode: DisplayMode;
}

export const HomeCarouselSection = ({
    title,
    children,
    description,
    size,
    showArrows = true,
    theme = 'light',
    onSlideChange,
    className,
    displayMode,
}: HomeCarouselSectionProps) => {
    const handleSlideChange = (event: OnChangeSlideEvent) => {
        onSlideChange?.(event.currentIndex);
    };

    return (
        <Carousel onChangeSlide={handleSlideChange}>
            <div className="flex w-full flex-col items-start justify-between md:flex-row">
                <div className="pb-8 md:pb-0 w-full md:w-[42.5%]">
                    <div className="flex flex-col gap-6 md:gap-11 px-6 md:p-8 md:pl-[12.5rem] md:pr-40 md:pt-20">
                        <h3
                            className={`text-3xl md:text-16 leading-[100%] tracking-[-0.125rem] ${_themeVariants({ theme })}`}
                        >
                            {title}
                        </h3>
                        <p
                            className={`md:w-[30rem] pr-12 text-gray-700 text-base md:text-5 md:leading-[130%] md:tracking-[0.025rem] ${_themeVariants({ theme })}`}
                        >
                            {description}
                        </p>
                        {showArrows && displayMode === DisplayMode.Desktop && (
                            <div className="flex gap-[1.3rem] md:right-12 md:mt-14">
                                <CarouselPrevious
                                    size={'square64'}
                                    color={'glassy'}
                                >
                                    <ArrowLeft className="size-6" />
                                </CarouselPrevious>
                                <CarouselNext
                                    size={'square64'}
                                    color={'glassy'}
                                >
                                    <ArrowRight className="size-6" />
                                </CarouselNext>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-full md:w-[57.5%]">
                    <CarouselContent>
                        {React.Children.map(children, child => (
                            <CarouselItem
                                className={`${_carouselItemVariant({ size })} ${className}`}
                            >
                                {child}
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                </div>
                {displayMode === DisplayMode.Mobile && showArrows && (
                    <div className="flex w-full px-6 justify-end mt-8 gap-2">
                        <CarouselPrevious size={'square42'} color={'glassy'}>
                            <ArrowLeft className="size-6" />
                        </CarouselPrevious>
                        <CarouselNext size={'square42'} color={'glassy'}>
                            <ArrowRight className="size-6" />
                        </CarouselNext>
                    </div>
                )}
            </div>
        </Carousel>
    );
};
