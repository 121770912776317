import React from 'react';
import { Link, useLoaderData } from '@remix-run/react';
import type { loader as homeLoader } from '~/routes/_app._index';
import { ReactComponent as ArrowRight } from '@rocco/icons/svg/arrow/arrow-right.svg';
import { Button as RoccoButton } from '@rocco/components/button';
import { VideoBackground } from '@rocco/ui/banner/components/VideoBackground';
import { useCTAHandlers } from '~/ui-rocco/hooks/use-home-cta-actions';

export const HomePageIntro = () => {
    const { introContent } = useLoaderData<typeof homeLoader>();
    const { video, content } = introContent;
    const { handleCTAClick } = useCTAHandlers('HomePageIntro');

    return (
        <section className="flex w-full flex-col-reverse items-start justify-between bg-white md:flex-row qhd:mx-auto qhd:max-w-[1920px]">
            <div className="w-full md:w-1/2">
                <div className="flex flex-col gap-12 px-6 py-20 md:p-8 md:pl-[12.5rem] md:pt-[16rem] md:pb-0">
                    <div
                        className="text-[2.75rem] md:text-20 leading-[100%] tracking-[-0.125rem]"
                        dangerouslySetInnerHTML={{ __html: content?.title }}
                    />
                    <div>
                        <p className="text-base text-gray-700 md:text-5 md:leading-[130%] md:tracking-[0.025rem]">
                            {content?.description}
                        </p>
                        {content?.listItems ? (
                            <ul className="flex flex-col gap-2.5 md:gap-2 mt-6 md:mt-7.5">
                                {content?.listItems.map((item, index) => (
                                    <li
                                        key={`intro-list-item-${index}`}
                                        className="flex items-center gap-4 md:gap-6.5"
                                    >
                                        <span className="flex size-5.5 text-[0.6875rem] font-medium items-center justify-center rounded-full bg-brick-50">
                                            {index + 1}
                                        </span>
                                        <span className="text-base md:text-5 leading-[130%] tracking-[0.025rem] text-gray-700">
                                            {item}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        ) : null}
                    </div>

                    <div>
                        <RoccoButton color="primary" size={'larger'} asChild>
                            <Link
                                to={content?.CTAButton?.link ?? '/login'}
                                onClick={() => handleCTAClick()}
                            >
                                {content?.CTAButton?.text}
                                <ArrowRight className="ml-2.5 w-5" />
                            </Link>
                        </RoccoButton>
                    </div>
                </div>
            </div>
            <div className="flex h-full w-full items-center justify-center md:h-full md:w-1/2">
                <div className="relative flex size-full aspect-[414/467] h-full items-center justify-center text-sm text-gray-500 md:aspect-[960/1080]">
                    {video?.videoCover ||
                    video?.videoMp4 ||
                    video?.videoWebM ? (
                        <VideoBackground
                            {...video}
                            overlay={false}
                            backgroundTheme="transparent"
                        />
                    ) : null}
                </div>
            </div>
        </section>
    );
};
