import React from 'react';
import type { CategoryTileViewModel } from '@rocco/ui/tile-category/view-models/category';
import type { AppDisplayModeType } from '~/server/types';
import type { TileViewModel } from '@rocco/ui/tile-shared/view-models/tile';
import { CarouselSection } from '@rocco/ui/layout/components/CarouselSection';
import { Tile } from '@rocco/ui/tile-shared/components/Tile';
import { useAtomValue } from 'jotai/index';
import {
    type HomePageTrackingSection,
    remixHomePageTrackingContext,
} from '@ui-rocco/hooks/use-home-page-tracking';

export const HomePageFeaturedCategories = ({
    categories,
    title,
    section,
    appDisplayMode,
}: {
    categories: CategoryTileViewModel[];
    title: string;
    section: HomePageTrackingSection;
    appDisplayMode: AppDisplayModeType;
}) => {
    const isMobile = appDisplayMode === 'mobile';
    const categoryTiles: TileViewModel<CategoryTileViewModel>[] = categories
        ? categories.map((category) => ({
              aspectRatio: 'category',
              model: category,
          }))
        : [];

    const { onCategorySliderSlideChange } = useAtomValue(
        remixHomePageTrackingContext
    );

    return categoryTiles.length > 0 ? (
        <CarouselSection
            title={isMobile ? undefined : title}
            showArrows={!isMobile}
            onChangeSlide={(event) =>
                onCategorySliderSlideChange?.(section, event)
            }
        >
            {categoryTiles.map((tile) => (
                <Tile key={`categoryTiles-${tile.model.id}`} tile={tile} />
            ))}
        </CarouselSection>
    ) : null;
};
