import React, { useRef, useState, useEffect } from 'react';
import { cn } from '@rocco/utils/cn';

interface TextRotateProps {
    texts: string[];
    rotationDuration?: number;
    pauseDuration?: number;
    prefixText?: string;
    className?: string;
    subfixText?: string;
}
export const TextRotate = React.memo(function TextRotate({
    texts,
    rotationDuration = 8,
    pauseDuration = 0.5,
    prefixText = '',
    subfixText = '',
    className,
}: TextRotateProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isInViewport, setIsInViewport] = useState(false);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => setIsInViewport(entry?.isIntersecting ?? false),
            { threshold: 0.1 },
        );
        if (containerRef.current) {
            observer.observe(containerRef.current);
        }
        return () => observer.disconnect();
    }, []);
    const totalDuration = rotationDuration + pauseDuration * texts.length;
    const angle = 360 / texts.length;
    return (
        <div
            ref={containerRef}
            className={cn('relative mx-auto', className)}
            style={
                {
                    '--rotation-angle': `${angle}deg`,
                    '--rotation-duration': `${totalDuration}s`,
                } as React.CSSProperties
            }
        >
            <div className="flex flex-col items-start md:flex-row md:items-center text-left">
                {prefixText && (
                    <span className="md:whitespace-pre h-[1.16em] leading-[1.16em] md:h-[1em] md:leading-[1em]">
                        {prefixText}
                    </span>
                )}
                <span className="inline-block">
                    <div className="relative h-[1.16em] leading-[1.16em] md:h-[1em] md:leading-[1em] w-auto [perspective:3000px]">
                        <div
                            className={cn(
                                'transform-gpu absolute w-full h-full',
                                'will-change-transform',
                                '[transform-style:preserve-3d]',
                                '[transform-origin:50%_50%]',
                                'origin-center',
                                '[animation-direction:reverse]',
                                isInViewport
                                    ? 'animate-text-rotate'
                                    : 'animate-none',
                            )}
                        >
                            {texts.map((text, index) => (
                                <div
                                    key={index}
                                    className="absolute w-full whitespace-nowrap text-left will-change-transform [-webkit-backface-visibility:hidden] [backface-visibility:hidden]"
                                    style={{
                                        transform: `rotateX(${angle * index}deg) translateZ(0.55em)`,
                                    }}
                                >
                                    {text}
                                </div>
                            ))}
                        </div>
                    </div>
                </span>
                {subfixText && (
                    <span className="md:whitespace-pre">{subfixText}</span>
                )}
            </div>
        </div>
    );
});
