import React from 'react';
import { Link, useLoaderData } from '@remix-run/react';
import type { loader as homeLoader } from '~/routes/_app._index';
import { ReactComponent as ArrowRight } from '@rocco/icons/svg/arrow/arrow-right.svg';
import { Button as RoccoButton } from '@rocco/components/button';
import { Image } from '@rocco/components/image';
import { useCTAHandlers } from '~/ui-rocco/hooks/use-home-cta-actions';

export const HomePageMedia = () => {
    const { mediaSection } = useLoaderData<typeof homeLoader>();
    const { handleCTAClick } = useCTAHandlers('HomePageMedia');

    return (
        <section className="flex w-full flex-col items-center bg-[#F2F2F2] px-6 py-20 md:p-8 md:pb-[11.75rem] md:pt-[10.625rem]">
            {mediaSection?.title && (
                <div
                    className="text-center mb-6 md:mb-5 text-[2.75rem] md:text-20 leading-[100%] tracking-[-0.125rem]"
                    dangerouslySetInnerHTML={{ __html: mediaSection.title }}
                />
            )}
            <p className="text-center text-base text-gray-700 md:max-w-[46.875rem] md:text-5 md:leading-[130%] md:tracking-[0.025rem]">
                {mediaSection?.description}
            </p>
            <div className="mt-8 mb-12 md:mb-35 md:mt-12">
                <RoccoButton color="primary" asChild>
                    <Link
                        to={mediaSection?.CTAButton?.link ?? '/login'}
                        onClick={() => handleCTAClick()}
                    >
                        {mediaSection?.CTAButton?.text}
                        <ArrowRight className="ml-1.5 w-4" />
                    </Link>
                </RoccoButton>
            </div>
            {mediaSection?.video?.videoCover ? (
                <div className="flex w-full items-center justify-center">
                    <div className="relative flex w-full md:max-w-[75.125rem] aspect-[1208/677]">
                        <Image
                            src={mediaSection?.video?.videoCover ?? ''}
                            overrideSrc={mediaSection?.video?.videoCover ?? ''}
                            alt="Project Management Preview"
                            fill={true}
                            fit="cover"
                        />
                    </div>
                </div>
            ) : null}
        </section>
    );
};
