import React, { useRef, useState } from 'react';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@rocco/components/accordion';
import { useLoaderData } from '@remix-run/react';
import type { loader as homeLoader } from '~/routes/_app._index';
import { Button as RoccoButton } from '@rocco/components/button';

const FAQ_DISPLAY_COUNT = 6;

export const HomePageFAQ = () => {
    const { faqs } = useLoaderData<typeof homeLoader>();
    const openedItemRef = useRef<HTMLDivElement>(null);

    const [isViewAll, setIsViewAll] = useState(false);

    if (faqs.length === 0) {
        return null;
    }

    return (
        <section className="py-20 md:pt-48 md:pb-0">
            <div className="px-6 md:px-12">
                <div className="flex flex-col gap-12">
                    <h4 className="text-xl font-medium leading-[1.3]">FAQs</h4>
                    <Accordion type="multiple">
                        {faqs
                            .slice(
                                0,
                                isViewAll ? faqs.length : FAQ_DISPLAY_COUNT
                            )
                            .map((faq) => (
                                <AccordionItem
                                    key={faq.question}
                                    value={faq.question || ''}
                                >
                                    <div ref={openedItemRef}>
                                        <AccordionTrigger
                                            variant="narrow"
                                            icon="plus"
                                            openIcon="minus"
                                        >
                                            <div className="w-full flex items-center justify-between">
                                                <span
                                                    className="text-xl font-medium text-left"
                                                    dangerouslySetInnerHTML={{
                                                        __html: faq.question,
                                                    }}
                                                />
                                            </div>
                                        </AccordionTrigger>
                                        <AccordionContent>
                                            <div
                                                className="py-2 grid"
                                                dangerouslySetInnerHTML={{
                                                    __html: faq.answer || '',
                                                }}
                                            />
                                        </AccordionContent>
                                    </div>
                                </AccordionItem>
                            ))}
                    </Accordion>
                    {faqs.length > FAQ_DISPLAY_COUNT && (
                        <div className="flex justify-start">
                            <RoccoButton
                                color="transparent"
                                size={'compact'}
                                shape={'square'}
                                onClick={() => setIsViewAll(!isViewAll)}
                            >
                                <span className="underline">
                                    {`View ${isViewAll ? 'Less' : 'More'}`}
                                </span>
                            </RoccoButton>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
